



import { useState, useEffect } from "react";
import MKTypography from "components/MKTypography"; 
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import MKAlert from "components/MKAlert";
import CloseIcon from "@mui/icons-material/Close";

import Dropdowns from "components/AppKey/Dropdowns";
import { useNavigate } from "react-router-dom"
import { appAuth } from "../../contexts/AppContext"

export default function AppOption({app}) { 

    const [errorMessage, setErrorMessage] = useState("");
 
    const [appData, setAppData] = useState(app)
    const [showTestEmailExt, setTestEmailExt] = useState(false);

    const { testAppEmailExt, requestError, updateApp, setLoadingSuccessData, currentApp, setCurrentApp} = appAuth()

    const toggleEmailExtModal = () => setTestEmailExt(!showTestEmailExt);

    const updateAppData = async (value, option) => { 


        setAppData({
            ...appData,
            [option]: value
        })

        setCurrentApp({
            ...currentApp,
            [option]: value
        })
        
        let app = await updateApp(appData.appId, option, value)

        console.log("updateAppData app " , app)

        
    }

    const onChangeValue = async (evt) => { 
        setAppData({
            ...appData,
            [evt.target.name]: evt.target.value
        })
    }



    const updateSendGridHandler = () => { 

        updateAppData(appData.sgKey, "emailExtensionAPIKey")
        updateAppData(appData.sgEmail, "emailExtensionSenderEmail")
    }





    const testSendGridEmail = async () => {
        let result = await testAppEmailExt(app.appId, appData.testedEmail) 
        if (result === true) setLoadingSuccessData({message:"Your tested email has been sent."})
        else if(result && result.error) setErrorMessage(result.error.message)
    }


    return( 
        <MKBox p={2}>
            <MKTypography variant="h5" >
                Application Options
            </MKTypography>
        
            <Container>
                
                <Grid container spacing={3} mt={2}  pb={5}>
                    <Grid item xs={12} md={5} lg={5}>
                        <Stack spacing={3}>
                            

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                APP USER NAME LOGIN:
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Turn on/off App user name login
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.userNamesEnabled} onChange={() => updateAppData(!appData.userNamesEnabled, "userNamesEnabled")} />
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleUsernameSwitch}
                                    >
                                    Allow App user name login
                                    </MKTypography>
                                </MKBox> 
                            </MKBox>

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                ALLOW ANONYMOUS LOGIN
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Turn on/off App anonymous login
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.anonymousLoginEnabled}  onChange={() => updateAppData(!appData.anonymousLoginEnabled, "anonymousLoginEnabled")}/>
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleAnonLoginSwitch}
                                    >
                                    Allow App anonymous login
                                    </MKTypography>
                                </MKBox> 
                            </MKBox>

                            <MKBox>
                                <MKBox alignItems="center" justifyContent="left" display="flex" >
                                    <MKTypography variant="h6" color="text">
                                    APP SIGNUP
                                    </MKTypography>

                                    <Dropdowns items={["OPEN",  "INVITE"]} defaultValue = { appData.signup.toUpperCase()} onChange={(val) => { 
                                        updateAppData(val.toLowerCase(), "signup")
                                    }} />

                                </MKBox>

                                
                            </MKBox>

                            <MKBox>
                                <MKBox alignItems="center" justifyContent="left" display="flex" >
                                    <MKTypography variant="h6" color="text">
                                    HANDLE TYPE
                                    </MKTypography>

                                    <Dropdowns items={["EMAIL",  "PHONE", "BOTH"]} defaultValue = {appData.handleType.toUpperCase()} onChange={(val) => { 
                                        updateAppData(val.toLowerCase(), "handleType")
                                    }} />

                                </MKBox>

                                
                            </MKBox>

                           

                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={2} lg={2}>

                    </Grid>

                    <Grid item xs={12} md={5} lg={5} >
                    <Stack spacing={3}>
                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                    EMAIL EXTENSIONS
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Set your own email extensions for your application.
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.emailExtension} onChange={() => updateAppData(!appData.emailExtension, "emailExtension")} />
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleEmailExtSwitch}
                                    >
                                    Enable Email Extensions
                                    </MKTypography>
                                </MKBox> 
                                {appData.emailExtension &&  
                                    <MKInput
                                        variant="standard"
                                        label="Enter SendGrid API Secret Key"
                                        placeholder="API Secret Key" 
                                        name='sgKey' defaultValue={appData.sgKey} onChange={onChangeValue}
                                        fullWidth
                                    />
                                }
                                
                            </MKBox>
                            {appData.emailExtension &&   

                            <MKBox container >
                                <MKBox> 
                                    <MKTypography variant="h6" color="text">
                                        SendGrid Sender Email
                                    </MKTypography>

                                    <MKTypography variant="body2" color="text"> 
                                    Enter Sender Email (This email will be used to send email to user. Make sure you verifiy sender identity with SendGrid)
                                    </MKTypography>


                                    <MKInput
                                        variant="standard"
                                        label="Enter SendGrid Sender Email"
                                        placeholder="email"
                                        type="email"
                                        name='sgEmail' defaultValue={appData.sgEmail}
                                        fullWidth
                                    />
                                </MKBox>

                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={6} md={4} lg={4}>
                                        
                                        <MKButton variant="gradient" color="primary" onClick={updateSendGridHandler}>
                                            Save
                                        </MKButton>
                                    </Grid>

                                    <Grid item xs={6} md={4} lg={4}></Grid>

                                    <Grid item xs={6} md={4} lg={4}>
                                        {appData.sgKey &&  
                                            <MKButton variant="gradient" color="info" onClick={toggleEmailExtModal}>
                                                test extension
                                            </MKButton>
                                        }
                                    </Grid>
                                </Grid>
                            </MKBox>

                            }
                            

                        </Stack>
                    </Grid>
                    
                </Grid> 
            </Container>




                                        
            <Modal open={showTestEmailExt} onClose={toggleEmailExtModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showTestEmailExt} timeout={300}>
                <MKBox
                position="relative"
                sx={{ maxWidth: 'md' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
                >
                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h5">Test Email Extensions</MKTypography>
                    <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleEmailExtModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                    <MKTypography variant="body">Please enter an email for sending a tested email.</MKTypography>

                    <MKInput name="testedEmail" variant="standard" label="Send to email" placeholder="you@yourmail.com"
                    onChange={onChangeValue} 
                    required  
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    />
                

                </MKBox>
                <Divider sx={{ my: 0 }} />

                {errorMessage  && <MKAlert color="error" >Whoop! {errorMessage}</MKAlert>}

                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                    <MKButton variant="gradient" color="dark" onClick={toggleEmailExtModal}>
                    cancel
                    </MKButton>
                    <MKButton variant="gradient" color="primary" onClick={testSendGridEmail}>
                    Submit
                    </MKButton>
                </MKBox>
                </MKBox>
            </Slide>
            </Modal>



        </MKBox>
    )


}