
export const Config = {  
    LOCAL:{
        REST_API : 'http://localhost:3003',
        tokenizationKeys:"sandbox_d5j3zcpf_f3gwz3xzcfy37xnz"

    },
    DEV:{
        REST_API : 'https://api-dev.appkey.io',
        tokenizationKeys:"sandbox_d5j3zcpf_f3gwz3xzcfy37xnz"
    },
    PROD:{
        REST_API : 'https://api.appkey.io',
        tokenizationKeys:"sandbox_d5j3zcpf_f3gwz3xzcfy37xnz"
    }
}