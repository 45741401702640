

import { useState, useEffect, useRef } from "react";
import Slide from "@mui/material/Slide";
import { Table } from "react-bootstrap" 
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox"; 
import MKButton from "components/MKButton"; 
import MKTypography from "components/MKTypography"; 
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
 
import AppMenu from "components/AppKey/AppMenu";
import localeList from "components/AppKey/locales.json"
import LocaleSelection from "components/AppKey/LocaleSelection";
import { appAuth } from "../../contexts/AppContext"
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function EmailTemplate({app }) {
 
    const {updateAppLocale, updateEmailTemplate, getAppEmailTemplate} =  appAuth()
 
    const [appEmailTemplates, setAppEmailTemplates] = useState({});
    const [updateOn, setUpdateOn] = useState("signUp")
    const [templates, setTemlates] = useState()
    const [toUpdate, setUpdate] = useState()
    const [showEdit, setShowEdit] = useState(false)
    const [menuIndex, setMenuIndex] = useState(0)
    const [locale, setLocale] = useState("EN")
    const [appLocales, setAppLocales] = useState([{text:"EN", value:"EN"}])
    const [appLocaleRender, setAppLocaleRender] = useState([])
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showModifyModal, setShowModifyModal] = useState(false)
    const titleTexts = ["Handle address verification", "Account Invitation", "Account signup success"]
    const bodyTexts = ["When a user signs up a phone number or email address to the application, you can send them a confirmation code to verify that they own the requested handle.", "When a user is invited to sign up to an application, an invitation message is sent to their phone number or email address.", "After a user signs up, you can send them a signup success message to their email address or phone number. "]
    const renderRef = useRef(false) 


    useEffect(() => {  
        if(appEmailTemplates && appEmailTemplates[locale]){
            let template = appEmailTemplates[locale].filter(item => item.templateName == updateOn)[0]  
            setTemlates(appEmailTemplates[locale]) 
            setUpdate(template)
        }

    }, [appEmailTemplates]) 
 

    useEffect(() => {  
        if(appEmailTemplates && appEmailTemplates[locale]){
            let template = appEmailTemplates[locale].filter(item => item.templateName == updateOn)[0]   
            setUpdate(template)
        }

    }, [updateOn]) 


    useEffect(() => {
        console.log("render start ")

        async function fetchApp() {

            let emailTemplate = await getAppEmailTemplate(app.appId)
            if(emailTemplate) setAppEmailTemplates(emailTemplate) 
            
        
            setAppLocales(prevItems => { 
                return [];
            }); 
            setAppLocaleRender(prevItems => { 
                return [];
            }); 

        
            app.locales.map(locale => {
                setAppLocales(prevItems => { 
                    return [...prevItems, {text:`${locale} - ${findCountryByLocale(locale)}`, value:locale}];
                }); 

                
                setAppLocaleRender(prevItems => { 
                    return [...prevItems, makeAppLocaleRecord(locale)];
                });  
                
            }) 

           

                
        }

        if (renderRef.current === false) {  
            fetchApp()  
          
            return () => {
                renderRef.current = true
                console.log("render clean up. ")
            }
        }

       

    }, []) 



    const removeAppLocale = (record) => { 
        console.log("removeAppLocale locale = ", record) 

        setAppLocaleRender(
            appLocaleRender.filter(item => {
              return item.id !== record.id
            })
        ) 
        
    }

    const makeAppLocaleRecord = (locale) => {  
        let record = {
            id:`id-${locale}`,
            locale: locale,
            language:findCountryByLocale(locale),
           
        } 
        return record 
    }


    const findCountryByLocale = (code) =>{
        let country = localeList.list.filter(item => item.code == code)[0];
        if (code === "EN") return" English (default)";
        return country ? country.name : "";
    }


    const handleSelectLocalEmailTemplate = (evt) => {
        
        console.log("handleSelectLocalEmailTemplate ", evt.target.value)
        let value = evt.target.value
        if (appEmailTemplates[value]){

            console.log("appEmailTemplates[value] ", appEmailTemplates[value])

            setLocale(value) 
            setTemlates(appEmailTemplates[value])

            let template = appEmailTemplates[value].filter(item => item.templateName == updateOn)[0]  
            setUpdate(template)
        }
    }; 

    const clickEditIcon = () => {
        setShowEdit(!showEdit)
    }

    const toggleInfoModal = () => {
        setShowInfoModal(!showInfoModal)
    }


    const toggleModalModify = () => {
        setShowModifyModal(!showModifyModal)
    }


    const onSave = async () => { 

        console.log("toUpdate ", toUpdate);
        setShowEdit(!showEdit) 
        await updateEmailTemplate(toUpdate)
       
    }

    function handleFieldValueChange(e) {
        setUpdate({
            ...toUpdate,
            [e.target.name]: e.target.value,
        });
    }

    const onSelectLocale = (item) => {
        if(item.code === "EN") return

        setAppLocaleRender(prevItems => { 
            return [...prevItems, makeAppLocaleRecord(item.code)];
        });
    }

    const cancelAppLocale = () => {

        setAppLocaleRender(prevItems => { 
            return [];
        }); 

        app.locales.map(locale => { 
            setAppLocaleRender(prevItems => { 
                return [...prevItems, makeAppLocaleRecord(locale)];
            });  
             
        })

        toggleModalModify()
    }

    const saveAppLocaleHandler = async () => {
        let locales = appLocaleRender.map(item => item.locale)
        console.log("saveAppLocaleHandler locales ", locales)

        setAppLocales(prevItems => { 
            return [];
        }); 

        locales.map(locale => {
            setAppLocales(prevItems => { 
                return [...prevItems, {text:`${locale} - ${findCountryByLocale(locale)}`, value:locale}];
            });  
        })
 
        await updateAppLocale({appId:app.appId, locales:locales})  

        toggleModalModify()
    }

    return (
        <>
        {appEmailTemplates && appLocales && 
    
        <MKBox>

            <AppMenu clickItemHandler={ (item) => {setUpdateOn(["signUp", "invite", "signUpSuccess"][item.index]); setMenuIndex(item.index)}}  items={[{icon:"face", label:"Verification", active:true, index:0}, {icon:"send", label:"Invite", index:1}, {icon:"manage_accounts", label:"Sign Up", index:2}]}/>

            <MKTypography variant="body2" color="black">Set your handle template locale for user</MKTypography> 
           

            <Stack direction="row" alignItems="center"  py={2}>
                

                <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
                    
                    <Select
                        labelId="simple-select-standard-label"
                        id="simple-select-standard" 
                        onChange={handleSelectLocalEmailTemplate}
                        defaultValue="EN"
                    >
                        {
                            appLocales.map(function(item, index){ 
                                return(
                                    <MenuItem value={item.value}  key={index}>{item.text}</MenuItem> 
                                )
                            })
                        }


                    </Select>
                </FormControl>

                
                <MKButton variant="text" color="info" onClick={toggleModalModify}>
                    <Icon>language</Icon> &nbsp;Edit Locale
                </MKButton>
            </Stack>
             

            <MKTypography variant="body" color="black">{titleTexts[menuIndex]}</MKTypography>
            
            <MKTypography color="black" variant="body2" py={1}>{bodyTexts[menuIndex]} <span style={{color:"blue", cursor:"pointer"}} onClick={toggleInfoModal}>Read more</span></MKTypography>
          
            <Stack direction="row" alignItems="center">
                <MKTypography variant="h5" color="black">Message</MKTypography>
                <MKButton variant="text" color="info"size="large" onClick={clickEditIcon}>
                    <Icon >edit</Icon>
                </MKButton>
            </Stack>

            {toUpdate &&  
                <MKBox  sx={{ maxWidth: 'md' }} p={2}>
                    {showEdit ? 
                         <Stack p={2} spacing={5}>

                            <MKInput type="email" name="replyTo" label="Reply To Email" value={toUpdate.replyTo } fullWidth id = "replyToInput"  placeholder="noreply@appkey.com" onChange={handleFieldValueChange}/>

                            <MKInput label="Subject" name="subject" value={toUpdate.subject } fullWidth id = "subjectInput" placeholder="Subject of the email" onChange={handleFieldValueChange}/>
                           
                            <MKInput name="htmlTemplate" value={toUpdate.htmlTemplate} fullWidth id = "messageInput"  multiline  rows={8} placeholder="hmtl code" onChange={handleFieldValueChange}/>
                            
                            <Stack direction="row" spacing={5} mt={3}>
                                <MKButton variant="outlined" color="info" onClick={()=> setShowEdit(!showEdit)}>Cancel</MKButton>
                                <MKButton variant="gradient" color="primary" onClick={onSave}>Save</MKButton>
                            </Stack>
                        </Stack>
                        :
                        <div dangerouslySetInnerHTML= {
                            { __html: toUpdate.htmlTemplate }
                        } />

                    }
                </MKBox>
            }




        </MKBox>

        }

        <Modal open={showModifyModal} onClose={toggleModalModify} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showModifyModal} timeout={200}>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                <MKBox
                    position="relative"
                    sx={{ width: '95%' }}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Modify App Locale</MKTypography> 
                        <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModalModify} />
                    </MKBox>
                    <Divider sx={{ my: 0 }} />

                    <MKBox p={2}>
                        <MKTypography variant="body">Please select the locale that you wish to add.</MKTypography>
                    </MKBox>
                
                    <MKBox p={1.5}> 
                        <LocaleSelection  defaultValue="" selectionHandler={onSelectLocale} />
                    </MKBox>


                    <Divider sx={{ my: 0 }} />

                    <Table >
                        <thead >
                            <tr key="label" >
                                <th><MKTypography variant="button">LOCALE</MKTypography></th>
                                <th ><MKTypography variant="button">LANGUAGE</MKTypography></th>
                                <th ><MKTypography variant="button">ACTION</MKTypography></th> 
                            </tr>
                        </thead>

                        <tbody className="locale-tbody">

                        {
                            appLocaleRender.map( (item, index) => {
                                
                             
                                return (
                                    <tr key={index + 1}>
                                        <td className="centerItem"> <MKTypography variant="button">{item.locale}</MKTypography></td>
                                        <td className="centerItem"><MKTypography variant="button">{item.language}</MKTypography></td>
                                        <td className="centerItem">
                                            {item.locale != "EN" && 
                                                <MKButton variant="outlined" color="error" onClick={() => removeAppLocale(item) }>
                                                    Delete
                                                </MKButton>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                             
                        }
                        
                        
                     </tbody>
                    </Table>
                   
                   
                    
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={cancelAppLocale}>
                            cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={saveAppLocaleHandler}>
                            Submit
                        </MKButton>
                    </MKBox>
                </MKBox>
            </Grid>
            </Slide>
        </Modal>


            
        <Modal open={showInfoModal} onClose={toggleInfoModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showInfoModal} timeout={200}>
            <MKBox
                position="relative"
                sx={{ maxWidth: 'md' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
            >
                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h5">Customize account management emails</MKTypography> 
                    <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleInfoModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />

                <MKBox p={2}>
                    <MKTypography variant="body">When you customize an handle template's subject line and message, you can use the following placeholder strings to represent values that will be filled in before sending:</MKTypography>
                </MKBox>
               
                <MKBox p={1.5}> 
                    <p>
                        <b>%APP_NAME%</b>	The name of your app. You can set this value by editing the App name field on the App page.<br/> 
                        <b>%HANDLE%</b>	The recipient's handle address or phone number.<br/> 
                        <b>%FULL_NAME%</b> The full name of the user. This is set by user on signup. <br/> 
                        <b>%CODE%</b>	The code to send to user for account verification.<br/> 
                         
                    </p>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="center" p={1.5}>
                    <MKButton variant="gradient" color="dark" onClick={toggleInfoModal}>
                        Close
                    </MKButton>
              
                </MKBox>
            </MKBox>
            </Slide>
        </Modal>
        
        </>

    )

}