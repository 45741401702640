 

import React, { useRef, useState, useEffect } from "react"

// react-router-dom components
import { Link, useNavigate } from "react-router-dom"

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";
 
 // Authentication layout components
import AuthLayout from "components/Layouts/AuthLayout"; 
import { useAuth } from "../contexts/AuthContext"
import { startRegistration, platformAuthenticatorIsAvailable, browserSupportsWebAuthn} from '@simplewebauthn/browser';
 


export default function SignUp() {
 
  const { register, registerComplete, confirmRegister } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [completeRegister, setCompleteRegister] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkedRef, setCheckedRef] = useState(false)
  

  const [signupData, setSignupData] = useState({})

  const navigate = useNavigate()

  const onChangeValue = async (evt) => { 
    setSignupData({
        ...signupData,
        [evt.target.name]: evt.target.value
    })
  } 



  useEffect(() => { 
    if (!browserSupportsWebAuthn()) { 
      setError( 'It seems this browser does not support Passkey Authentication.');
      return;
    }

  },[])

  const onKeyEnterDown = (event) => {
    if(event.keyCode == 13) handleRegisterSubmit()
  }

  async function completeRegisterHandler() {

    try {
      setLoading(true) 
      let authn = await registerComplete(signupData);
      if (authn.error) {
        setError(authn.error.message)
      }
      else {
        setMessage("Authenticator registered!") 
        setTimeout(() => {
          setCompleteRegister(false)
          navigate("/apps")
        }, 1500);
        
        
      } 
    } catch (error) {
      setError(error.message)
    }
    finally{
      setLoading(false)
    }
    
  }

  async function handleRegisterSubmit() {
    setMessage("")
    setError("")

    

    if(!signupData.email || !signupData.firstName || !signupData.lastName) {
      setError("Please enter all required fields.")
      return
    }

    if (!checkedRef){
      setError("Please agree the Terms and Conditions.")
      return
    } 

    if (!await platformAuthenticatorIsAvailable()) {
      setError("Your device doesn't have Passkey Authenticator. Please use any security key device to register.") 
      return;
    }
     
    if( completeRegister) completeRegisterHandler(); 
    else getRegisterChallenge(); 

    
  }


  function cancelRegister(){
    setCompleteRegister(false)
    setMessage("")
    setError("")
  }

  const checkedAgreeRef = async(event) => {
    console.log(`value: ${event.target.checked}`);
    setCheckedRef(event.target.checked)
    
  }

  const getRegisterChallenge = async() => {
    try {  
      setLoading(true)

      let challengeData = await register(signupData); 
      console.log("getRegisterChallenge challengeData ", challengeData)


      if(challengeData.error){
        setError(challengeData.error.message)
        return;
      }

      setLoading(false)

      let attResp = await startRegistration(challengeData);
      attResp.email = signupData.email;
      
      setLoading(true)

      let result = await confirmRegister(attResp); 

      console.log("confirmRegister result ", result)
      
      if (result.error) {
        setError(result.error.message)
        return
      }

      setMessage(result.message)
      
      setCompleteRegister(true); 
      
      setLoading(false)
    } catch (error) {
      console.log("getRegisterChallenge error ", error);
      if (error.message) {
        setError(error.message)
      }
      

    }
    finally{
      setLoading(false)
    }
  }

 
  return (
    <AuthLayout>
      <Card>
        <MKBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign Up
          </MKTypography>
          
        </MKBox>

        {error && 
          <MKAlert color="error">
            Whoop!&nbsp; {error}
          </MKAlert>
        }

        {message && 
          <MKAlert color="info">
            {message}
          </MKAlert>
        }

        <MKBox pt={4} pb={3} px={3}>
          <MKBox >  

            {completeRegister ?
              <div>

                <MKBox mb={2}>
                  <MKInput type="number" name='code' label="Confirmed Code" fullWidth onChange={onChangeValue}  required onKeyDown = {onKeyEnterDown} />
                </MKBox>
                
              </div>
              :
              <div>
                <MKBox mb={2}>
                  <MKInput type="text" name='firstName' label="First Name" autoFocus fullWidth  onChange={onChangeValue} required />
                </MKBox> 

                <MKBox mb={2}>
                  <MKInput type="text" name='lastName' label="Last Name" fullWidth  onChange={onChangeValue} required />
                </MKBox> 

                <MKBox mb={2}>
                  <MKInput type="email" name='email' label="Email" fullWidth onChange={onChangeValue}  required />
                </MKBox>

                <MKBox mb={2}>
                  <MKInput type="text" name='company' label="Company" fullWidth onChange={onChangeValue} />
                </MKBox>


                <MKBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox required onChange={checkedAgreeRef} id='agree'  />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I ACCEPT the&nbsp;
                  </MKTypography>
                  <MKTypography
                    component="a"
                    href="/agreement"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    textGradient
                  >
                    Terms and Conditions
                  </MKTypography>
                </MKBox>

                <MKBox display="flex" alignItems="center" ml={-1}>
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    By clicking ACCEPT you acknowledge that you read and accept all terms and conditions in the Agreement, and that your acceptance constitutes a binding and enforceable legal agreement.
                  </MKTypography>

                </MKBox>
              </div>
            }

           


            <MKBox mt={4} mb={1} textAlign="center">
                {loading ?   
                    <CircularProgress  color="primary"/>
                    : 
                    <MKButton  variant="contained" color="primary" fullWidth onClick = {handleRegisterSubmit}>
                     {completeRegister ? "submit" :  "Get Started"}  
                  </MKButton>
                }
            </MKBox> 

            {completeRegister &&
              <MKButton type="submit" variant="contained" color="warning" fullWidth onClick={cancelRegister}>
                cancel
              </MKButton>
            }

            
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/signin"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </AuthLayout>
  );
}
 