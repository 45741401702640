 
import { useState, useEffect, useRef } from "react";
import MKTypography from "components/MKTypography"; 
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment"; 
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
//import Table from "components/Table";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import MKAlert from "components/MKAlert";
import MKBadge from "components/MKBadge";

import Pagination from '@mui/material/Pagination';
import { startAuthentication} from '@simplewebauthn/browser';
import AppSignup from "components/AppKey/AppSignup";
import AppLogin from "components/AppKey/AppLogin";

 
import Dropdowns from "components/AppKey/Dropdowns";
import { appAuth } from "../../contexts/AppContext"
 
 

export default function AppUser({app}) { 


    const {currentApp, deleteAppSignup, deleteAppUser, updateAppUserStatus, createAppInvite, login, verifyComplete, verify, createAppSignup, getAppSignup, getAppUser} = appAuth()
    const [filter, setFilter] = useState({appId:app.appId, type:"all", limit:25, offset: 0})
    const [search, setSearch] = useState("")
    const [signupData, setSignupData] = useState([]);
    const [signupDataRender, setSignupDataRender] = useState([]);
    const [userData, setUserData] = useState([]);
    const [userDataRender, setUserDataRender] = useState([]);
    const [showAnonymous, setShowAnonymous] = useState(true);
    
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageSingup, setErrorMessageSignup] = useState("");
 
    const [inputData, setInputData] = useState({})
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [total, setTotal] = useState();

    const [state, setState] = useState({
        activeTabUser:0,
        showCreateSignup:false,
        showCreateInvite:false,
        showVerify:false,
        showDeleteAppUser:false,
        showDeleteSignup:false,
        user:{}

    });

    const signUpTableHeader = [
        
        { id: "handle",  name: "handle", align: "center" }, 
        { id: "status", name: "status", align: "center" }, 
        { id: "created", name: "created", align: "center" }, 
        { id: "control", name: "control", align: "center" },
    ]

    let userTableHeader = [
        { id:"number", name: "number", align: "left" },
        { id: "handle",  name: "handle", align: "center" },
        { id: "displayName",  name: "displayName", align: "center" }, 
        { id: "status", name: "status", align: "center" },
        { id: "lastLogin", name: "lastLogin", align: "center" },
        { id: "created", name: "created", align: "center" }, 
        { id: "control", name: "control", align: "center" },
    ]

    if(app.userNamesEnabled) {
        userTableHeader = [

            { id:"number", name: "number", align: "left" },
            { id: "handle",  name: "handle", align: "center" },
            { id: "displayName",  name: "displayName", align: "center" }, 
            { id: "userName",  name: "userName", align: "center" }, 
            { id: "status", name: "status", align: "center" },
            { id: "lastLogin", name: "lastLogin", align: "center" },
            { id: "created", name: "created", align: "center" }, 
            { id: "control", name: "control", align: "center" },

            
        ]
    }


    const renderRef = useRef(false)

 
    useEffect(() => {

        async function fetchAppData() { 
            if (!currentApp) {
                return
            } 
            else if(state.activeTabUser == 1) getAppSignupData()
            else getAppUserData()
            
        } 
        

        if (renderRef.current === false){
            fetchAppData()
            return () => {
                renderRef.current = true
                console.log("render clean up. ")
            }
        }

    }, [])



    useEffect(() => {

        if (!currentApp) {
            return
        } 
        else if(state.activeTabUser == 1) getAppSignupData()
        else getAppUserData()

    }, [state.activeTabUser])



    useEffect(() => {
        applyFilter()
    }, [page, rowsPerPage])

    const applyFilter = async () => {  
        
        if (state.activeTabUser === 0) getAppUserData()
        else getAppSignupData()

    }
 

    const getAppSignupData = async() => {
       
        setFilter({
            ...filter,
            ["search"]: search
        });

        let result = await getAppSignup(filter)

        setSignupData(prevItems => { 
            return [];
        }); 

        setSignupDataRender(prevItems => { 
            return [];
        }); 

        

        if (!result || !result.data) return

        setTotal(result.total || 0)

        for (let index = 0; index < result.data.length; index++) {

            
            let element = makeSignupRecord(result.data[index], index); 
            //element.number = index + 1

        
            setSignupData(prevItems => { 
                return [...prevItems, element];
            });  

            setSignupDataRender(prevItems => { 
                return [...prevItems, element];
            });  
                
             
        } 
    }




    const getAppUserData = async() => {
        
        setFilter({
            ...filter,
            ["search"]: search
        });

        let result = await getAppUser(filter)

        setUserData(prevItems => { 
            return [];
        }); 

        setUserDataRender(prevItems => { 
            return [];
        });  

        if (!result || !result.data) return

        setTotal(result.total || 0)

        for (let index = 0; index < result.data.length; index++) {
            
            let element = makeUserRecord(result.data[index]); 
            element.number = index + 1 
          
            setUserData(prevItems => { 
                return [...prevItems, element];
            });  

            setUserDataRender(prevItems => { 
                return [...prevItems, element];
            });  
            
           
        } 
    }



    const handleTabUserType = (event, newValue) => {

        console.log("handleTabUserType newValue = ", newValue)
        setState({ ...state, ["activeTabUser"]:newValue }); 
    }

    function onKeyEnterSearch(event){
        if(event.keyCode == 13) applyFilter()
    }
 
    function onSearchUserSignup (evt)  { 
        let value = evt.target.value
        setSearch(value)
      
      

        if(state.activeTabUser == 1){ 
            setSignupDataRender(
                signupData.filter(data =>
                data.handle.indexOf(value) >= 0
            )); 

        }
        else {
            setUserDataRender(
                userData.filter(data =>
                    data.handle.indexOf(value) >= 0
            )); 

        }

        if(search === "") applyFilter()

    }



    const toggleCreateSignupModal = () => { 
        setErrorMessageSignup() 
        if (app.signup == "open")  setState({ ...state, ["showSignup"]:!state.showSignup })  
        else  setState({ ...state, ["showCreateInvite"]:!state.showCreateInvite })   
    }


    const toggleVerifyModal = () => { 
        setErrorMessageSignup()  
        setState({ ...state, ["showVerify"]:!state.showVerify })   
        
    }

    

    const toggleTestUserModal = (type) => {   
        setState({ ...state, [type]:!state[type] })   
    }

    const toggleDeleteSignupModal = (item) => {  
        setState({ ...state, ["showDeleteSignup"]:!state.showDeleteSignup, signUpToDelete:item })   

    }

    const toggleDeleteAppUserModal = (item) => {   
        setState({ ...state, ["showDeleteAppUser"]:!state.showDeleteAppUser, user:item })   
    }


    const loginAnonymousCompleteHandler = async(user) => { 
 
        let number = userDataRender.length + 1;
        
        let element = makeUserRecord(user); 
        element.number = number
        
        setTotal(number)

        setUserData(prevItems => { 
            return [...prevItems, element];
        });  

        setUserDataRender(prevItems => { 
            return [...prevItems, element];
        });  
        
    }

    const toggleShowAnonumous = (value) => {
        setShowAnonymous(value)

        if (value) {
            setUserDataRender(
                userData.filter(data =>
                    data.handle.indexOf("ANON_") >= 0
            )); 
        }
        else {
            setUserDataRender(
                userData.filter(data =>
                    data.handle.indexOf("ANON_")< 0
            )); 
        }

    }


    const updateAppUserStatusHandler = async (user, status) => {   
        let result = await updateAppUserStatus(user, status);

        if(result) {
          
          
           console.log("updateAppUserStatusHandler status ", status)
           
            setUserData(userData.map(item => { 
                if (item.appUserId === user.appUserId){ 
                    return {...item, status:status}
                    
                } 
                else return item
            })); 

            setUserDataRender(userDataRender.map(item => { 
                if (item.appUserId === user.appUserId){ 
                    return {...item, status:status}
                    
                } 
                else return item
            })); 


            // setUserDataRender(draft => {
            //     const item = draft.find(u =>
            //         u.appUserId === user.appUserId
            //     );
            //     item.status = status;
            // });
        }
    }


    const deleteSignupHandler = async () => {
        toggleDeleteSignupModal() 
        let result = await deleteAppSignup(state.signUpToDelete);
        if(result === true) {

            setSignupData(
                signupData.filter(data => data.handle!= state.signUpToDelete.handle)
            );  
            setSignupDataRender(
                signupDataRender.filter(data => data.handle!= state.signUpToDelete.handle)
            ); 
        }
    }


    const deleteAppUserHandler = async () => {

        toggleDeleteAppUserModal() 
        let result = await deleteAppUser(state.user);
        if(result) {

            setUserData(
                userData.filter(data => data.handle!= state.user.handle)
            );  
            setUserDataRender(
                userDataRender.filter(data => data.handle!= state.user.handle)
            ); 
        }
    }
  

     
    const handleCreateSignupInvite = async () => { 
        setErrorMessageSignup()

        let emails = inputData.signupInvite

        console.log("handleCreateSignupInvite emails ", emails)

        if(!validateSignupEmail(emails)){
            setErrorMessageSignup("Please enter valid email")
            return 
        } 
        let result = await createAppInvite(currentApp.appId, emails)
        
        if (result.invitedData) {

            for (let index = 0; index < result.invitedData.length; index++) { 
                let element = makeSignupRecord(result.invitedData[index], index); 
                element.number = index + 1

                setSignupData(prevItems => { 
                    return [element, ...prevItems];
                });

                setSignupDataRender(prevItems => { 
                    return [element, ...prevItems];
                });  
               
            }

            console.log("createAppInvite result ", result)

            // setSignupData(signupData.map((item, index) => { 
            //     return { ...item, number: index + 1 }; 
            // }));

            // setSignupDataRender(signupDataRender.map((item, index) => { 
            //     return { ...item, number: index + 1 }; 
            // }));

        }
        toggleCreateSignupModal()

    }


    const onKeyEnterDown = (event) => {
        if(event.keyCode == 13) handleVerifyHandle()
    }

    const handleVerifyHandle = async () => {
        setErrorMessageSignup()

        if(!validateSignupEmail(inputData.handle)){
            setErrorMessageSignup("Please enter valid email")
            return 
        } 

        let data = {
            appId: app.appId,
            handle : inputData.handle
        }

        let result = await verify(data); 
        if (result.error) {
            setErrorMessageSignup(result.error.message)
            return
        } 

        let attResp = await startAuthentication(result); 
        attResp.handle = data.handle;
        
        attResp.appId = data.appId;

        let authn = await verifyComplete(attResp, true);
        if (authn.error) {
            setErrorMessageSignup(authn.error.message)
        }
        else setState({ ...state, ["showVerify"]:!state.showVerify })   
        

    }


    const formatDate = (dateString) => {
        const options = { year: "2-digit", month: "2-digit", day: "2-digit", hour: 'numeric', hour12: true}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const formatFullDate = (dateString) => {
        const options = { year: "2-digit", month: "2-digit", day: "2-digit", hour: 'numeric', hour12: true, minute:'numeric'}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const makeSignupRecord = (item) => { 
        
       
        if (item.status == "active") item.status = <MKBadge variant="contained" badgeContent="active" color="success" size="xs" container />
        else item.status = <MKBadge variant="contained" badgeContent="pending" color="secondary" size="xs" container />
        item.created = formatDate(item.createdAt)
        item.updated = formatDate(item.updatedAt)
        item.hide = false

        

        item.control = <MKButton variant="text" color="error" onClick={ () => toggleDeleteSignupModal(item)} iconOnly><Icon>delete</Icon></MKButton>
        return item
          
    }

    const makeUserRecord = (item) => { 
       
        item.created = formatDate(item.createdAt)
        item.updated = formatDate(item.updatedAt)
        item.lastLogin = formatFullDate(item.lastLogin)
        item.hide = false  
        return item
          
    }


    function validateSignupEmail (emails){

        if(emails === "" || emails === undefined) return false

        emails = emails.split(",")
        for (let index = 0; index < emails.length; index++) {
            const email = emails[index];
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
                return false
            }
        }
        console.log("validateSignupEmail emails ", emails)
        return emails
    }

    /* 
    const handleCreateSignupData = async () => { 
        setErrorMessageSignup()

        let createSignup = { 
            displayName : inputData.displayName,
            handle: inputData.handle,
            appId: app.appId 
        }

        console.log("handleCreateSignupData createSignup ", createSignup)


        if(!createSignup.handle || !createSignup.displayName){
            setErrorMessageSignup("Please enter all fields")
            return 
        } 

        if(!validateSignupEmail(createSignup.handle) ){
            setErrorMessageSignup("Please enter valid email")
            return 
        } 

        let result = await createAppSignup(createSignup)
        if(result.error) setErrorMessageSignup(result.error.message)
        else {

            console.log("handleCreateSignupData ", result)
            let item  = makeSignupRecord(result)
            setSignupData(prevItems => { 
                return [item, ...prevItems];
            });  

            setSignupDataRender(prevItems => { 
                return [item, ...prevItems];
            });  

            toggleCreateSignupModal() 
        } 

    } */

    const onChangeInputData = (evt) => {
        setInputData({...inputData, [evt.target.name]: evt.target.value})
    }

    
    const handleChangeRowsPerPage = (value) => {

        console.log("handleChangeRowsPerPage newPage ", value);
        setFilter({
            ...filter,
            ["limit"]: value,
            ["offset"] : 1
        });

        //setTotalPage( Math.ceil(total/value)) 

        setRowsPerPage(value);
        setPage(1); 
       
    };

    const handleChangePage = (event, newPage) => {
        console.log("handleChangePage newPage ", newPage); 
        setPage(newPage);
        setFilter({
            ...filter,
            ["offset"]: newPage
        });

        
    };
  

    return( 
        <MKBox p={2}>
            <MKTypography variant="h5" color="black">Application Users</MKTypography>
            <MKBox  xs={12} display="flex" justifyContent={"space-between"} py={3}>

                <Grid container  >
                    <Grid item  xs={12} md={6} pb={2}>
                        <AppBar position="static">
                            <Tabs value={state.activeTabUser}  onChange={handleTabUserType}>
                                <Tab label="Users" value={0} />
                                {app.signup === "open" && <Tab label="Signups"  value={1}/> }
                                {app.signup === "invite" && <Tab label="Invites" value={1}  /> }
                            </Tabs>
                        </AppBar> 
                    </Grid> 
                    <Grid item  xs={12} md={6} textAlign="right" >
                        <MKInput
                            variant="standard"
                            placeholder="Search"
                            id = "searchValue"
                            name = "searchValue"
                            autoFocus={true}
                            value={search} 
                            onKeyDown = {onKeyEnterSearch}
                            onChange={e => {onSearchUserSignup(e)} }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ), 
                            }} />
                    </Grid> 
                 </Grid> 

            </MKBox> 
          
                
            <CustomTabPanel value={state.activeTabUser} index={0}> 
            
                <Grid container item xs={12} lg={12} justifyContent="space-between" >
                    <MKBox display="flex" alignItems="center" >
                        <MKBox alignItems="center" justifyContent="left" display="flex" >

                            <MKTypography variant="body2" color="text">  Show:  </MKTypography> 
                            <Dropdowns items={[25,  100, 150]} defaultValue = {rowsPerPage}    onChange={handleChangeRowsPerPage}  /> 
                            <MKTypography variant="body2" color="text"> entries  </MKTypography> 

                            <MKBox px={3}> 
                                <MKTypography variant="body2" color="text"> Total User: {total}  </MKTypography>
                            </MKBox>
                        </MKBox>

                        {app.anonymousLoginEnabled && 
                            <MKBox> 
                                <Switch checked={showAnonymous} onChange={() => toggleShowAnonumous(!showAnonymous)} /> 
                                <MKTypography variant="button"  color="text"  fontWeight="regular"  ml={1} >
                                    Show Anonymous 
                                </MKTypography>
                            </MKBox>
                        }
                    </MKBox>

                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Create user signup" placement="top">
                            <MKButton variant="outlined" color="info" onClick={() => toggleTestUserModal("showSignup")} px={2} iconOnly> <Icon>person_add</Icon> </MKButton>
                        </Tooltip>

                        <Tooltip title="Login app user" placement="top">
                            <MKButton variant="outlined" color="info" onClick={() => toggleTestUserModal("showLogin")} px={2} iconOnly><Icon>login</Icon></MKButton>
                        </Tooltip>
                        { app.anonymousLoginEnabled &&  
                        <Tooltip title="Login app anonumous user" placement="top">
                            <MKButton variant="outlined" color="info" onClick={() => toggleTestUserModal("showAnonymousLogin")} px={2} iconOnly><Icon>panorama_photosphere</Icon></MKButton>
                        </Tooltip>
                        }
                        <Tooltip title="Verify app user handle" placement="top">
                            <MKButton variant="outlined" color="info" onClick={() => toggleTestUserModal("showVerify")} px={2} iconOnly><Icon>fingerprint</Icon></MKButton>
                        </Tooltip>
                    </Stack>
                </Grid>

                
                <TableContainer sx={{ maxHeight: 650 }}>
                    <Table responsive="sm">
                        <thead>
                        <TableRow>
                        {userTableHeader.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            className="colunmHeader"
                            >
                            {column.name}
                            </TableCell>
                        ))}
                        </TableRow>
                        </thead>
                    <TableBody>
                        {userDataRender
                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((user) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={user.number}>
                                {userTableHeader.map((column) => {

                                    let value = user[column.id];

                                    if (column.id === "status") { 

                                        return (
                                            <TableCell key={column.id} align={column.align} className="tableCell">
                                                { value === "active" ?  <MKBadge variant="contained" badgeContent= {value} color="success" size="xs" container /> :
                                                                       <MKBadge variant="contained" badgeContent= {value} color="error" size="xs" container />
                                                }

                                            </TableCell>
                                        );
                                    }

                                    else if (column.id === "control") {
                                        return (
                                            <TableCell key={column.id} align={column.align} className="tableCell">
                                                { user.status === "active" ?   
                                                    <MKBox>
                                                        <MKButton  variant="text" color="error" onClick={ () => toggleDeleteAppUserModal(user)} iconOnly px={2}><Icon>delete</Icon></MKButton> &nbsp;
                                                        <Tooltip title="suspend this user" placement="top">
                                                           <MKButton variant="text" color="error" onClick={ () => updateAppUserStatusHandler(user, "suspended")} iconOnly px={2}><Icon>person_off</Icon></MKButton>
                                                        </Tooltip>
                                                    </MKBox>  :
                                                    <MKBox>
                                                        <MKButton variant="text" color="error" onClick={ () => toggleDeleteAppUserModal(user)} iconOnly px={2} ><Icon>delete</Icon></MKButton> &nbsp;
                                                        <Tooltip title="activate this user" placement="top">
                                                            <MKButton variant="text" color="info" onClick={ () => updateAppUserStatusHandler(user, "active")} iconOnly px={2}><Icon>settings_accessibility</Icon></MKButton>
                                                        </Tooltip>
                                                    </MKBox>
                                                }

                                            </TableCell>
                                        );
                                    }
                                    else { 


                                        return (
                                            <TableCell key={column.id} align={column.align} className="tableCell">
                                            {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>

                    
                    </Table>
                </TableContainer>

                
              
                {Math.ceil(total/rowsPerPage) > 1 &&  
                    <Grid container item xs={12} lg={5} justifyContent="center" mx="auto" py={3}> 
                        <Pagination count={Math.ceil(total/rowsPerPage)} showFirstButton showLastButton page={page} onChange={handleChangePage} />
                    </Grid>
                }
            </CustomTabPanel>


            <CustomTabPanel value={state.activeTabUser} index={1}> 

                <MKBox display="flex" justifyContent="space-between"> 
                    
                    <MKBox alignItems="center" justifyContent="left" display="flex">

                        <MKTypography variant="body2" color="text">  Show:  </MKTypography> 
                        <Dropdowns items={[25,  100, 150]} defaultValue = {rowsPerPage}  onChange={handleChangeRowsPerPage}   /> 
                        <MKTypography variant="body2" color="text"> entries  </MKTypography> 

                        <MKBox px={3}> 
                            <MKTypography variant="body2" color="text"> Total Record: {total}  </MKTypography>
                        </MKBox>
                    </MKBox>

                    
                    <Tooltip title="Add Record" placement="top">
                        <MKButton variant="outlined" color="info" onClick={toggleCreateSignupModal} px={2} iconOnly> <Icon sx={{ mr: 1 }}>person_add</Icon> </MKButton>
                    </Tooltip>

                </MKBox>
                {signupDataRender.length > 0 ?


                <TableContainer sx={{ maxHeight: 650 }}>
                <Table responsive="sm">
                    <thead>
                    <TableRow>
                    {signUpTableHeader.map((column) => (
                        <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className="colunmHeader"
                        >
                        {column.name}
                        </TableCell>
                    ))}
                    </TableRow>
                    </thead>
                <TableBody>
                    {signupDataRender
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user.number}>
                            {signUpTableHeader.map((column) => {

                                let value = user[column.id];

                                return (
                                    <TableCell key={column.id} align={column.align} className="tableCell">
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>


                </Table>
                </TableContainer>

 
                :
                    <MKTypography color="info" pr={4}>No Record</MKTypography>
                }

                {Math.ceil(total/rowsPerPage) > 1 &&  
                    <Grid container item xs={12} lg={5} justifyContent="center" mx="auto" py={3}> 
                        <Pagination count={Math.ceil(total/rowsPerPage)} showFirstButton showLastButton page={page} onChange={handleChangePage} />
                    </Grid>
                }

            </CustomTabPanel>





        <Modal open={state.showSignup} onClose={() => toggleTestUserModal("showSignup")} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showSignup} timeout={300}>
          <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
          <Card>
                <MKBox
                variant="contained"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center" 
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign Up 
                    </MKTypography>
                
                </MKBox>
           
                <MKBox pb={3} px={3}>
                    <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Signup to Application '{app.name}'</MKTypography> 
                         
                    </MKBox>
                    <MKBox display="flex" alignitems="center" justifyContent="space-between" px={2}>
                        <MKTypography variant="body2" color="text">Enter your user information</MKTypography>
                    </MKBox>
                    
                    <AppSignup app={app} dismiss={() => toggleTestUserModal("showSignup")}  onSuccess={getAppUserData}/>
                </MKBox>
                
                </Card>
            </Grid>
          </Slide>
        </Modal>

        


        <Modal open={state.showAnonymousLogin} onClose={() => toggleTestUserModal("showAnonymousLogin")} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showAnonymousLogin} timeout={300}>
          <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">

            <Card>
                <MKBox
                variant="contained"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center" 
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Login
                    </MKTypography>
                
                </MKBox>
           
                <MKBox pb={3} px={3}> 
                    <AppLogin app={app} anonymous = {true} dismiss={() => toggleTestUserModal("showAnonymousLogin")}  onSuccess ={ loginAnonymousCompleteHandler}/>

                 
                </MKBox>
                
            </Card>
            </Grid>
          </Slide>
        </Modal>


        <Modal open={state.showLogin} onClose={() => toggleTestUserModal("showLogin")} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showLogin} timeout={300}>
          <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">

            <Card>
                <MKBox
                variant="contained"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center" 
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Login
                    </MKTypography>
                
                </MKBox>
           
                <MKBox pb={3} px={3}> 

                    <AppLogin app={app} anonymous = {false} dismiss={() => toggleTestUserModal("showLogin")}  onSuccess ={() => { }}/>

                 
                </MKBox>
                
            </Card>
            </Grid>
          </Slide>
        </Modal>



        <Modal open={state.showCreateInvite} onClose={toggleCreateSignupModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showCreateInvite} timeout={300}>
            <MKBox
              position="relative" 
              sx={{ maxWidth: 'md' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox  p={2}>
                <MKTypography variant="h5">Enter user email to invite to signup.</MKTypography> 
                <MKTypography variant="body2" color="text">Use camma separation for multiple email</MKTypography>
              </MKBox>
              {errorMessageSingup && <MKTypography variant="button" color="error" px={2}>{errorMessageSingup}</MKTypography> }
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKInput  onChange={onChangeInputData}  name = "signupInvite"  label="Emails" multiline fullWidth rows={6} placeholder="jojo@appkeyf.com, toto@appkeyf.com"/>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleCreateSignupModal}>
                  cancel
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={handleCreateSignupInvite}>
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>

{/* 
        <Modal open={state.showCreateSignup} onClose={toggleCreateSignupModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showCreateSignup} timeout={300}>
            <MKBox
              position="relative" 
              sx={{ maxWidth: 'md' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox  p={2}>
                <MKTypography variant="h5">Enter user information to signup.</MKTypography>  
              </MKBox>
              {errorMessageSingup && <MKTypography variant="button" color="error" px={2}>{errorMessageSingup}</MKTypography> }
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKBox py={2}>
                    <MKInput  label="Display Name"  onChange={onChangeInputData}    fullWidth name="displayName" autoFocus={true}  />
                </MKBox>
                <MKBox >
                    <MKInput  label="Handle" onChange={onChangeInputData}   fullWidth name="handle" />
                </MKBox>
            </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleCreateSignupModal}>
                  cancel
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={handleCreateSignupData}>
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal> */}



        <Modal open={state.showVerify} onClose={toggleVerifyModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showVerify} timeout={300}>
            <MKBox
              position="relative" 
              sx={{ maxWidth: 'md' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox  p={2}>
                <MKTypography variant="h5">Enter user handle to verifiy.</MKTypography>  
              </MKBox>
              {errorMessageSingup && <MKTypography variant="button" color="error" px={2}>{errorMessageSingup}</MKTypography> }
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                
                <MKBox >
                    <MKInput  label="Handle"  onChange={onChangeInputData}   fullWidth name="handle"  onKeyDown = {onKeyEnterDown}/>
                </MKBox>
            </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleVerifyModal}>
                  cancel
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={handleVerifyHandle}>
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>

 
       



        <Modal open={state.showDeleteSignup} onClose={toggleDeleteSignupModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showDeleteSignup} timeout={300}>
            <MKBox
              position="relative"
              sx={{ maxWidth: 'md' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Are you sure to delete this app?</MKTypography> 
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body">This transaction can't undo.</MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleDeleteSignupModal}>
                  cancel
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={deleteSignupHandler}>
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>



        <Modal open={state.showDeleteAppUser} onClose={toggleDeleteAppUserModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={state.showDeleteAppUser} timeout={300}>
            <MKBox
              position="relative"
              sx={{ maxWidth: 'md' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Are you sure to delete this app user?</MKTypography> 
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body">This transaction can't undo.</MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleDeleteAppUserModal}>
                  cancel
                </MKButton>
                <MKButton variant="gradient" color="primary" onClick={deleteAppUserHandler}>
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal> 

        </MKBox>
    )




    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
        <MKBox
            role="tabpanel"
            hidden={value !== index}
            id={`app-tabpanel-${index}`}
            aria-labelledby={`app-tab-${index}`}
            {...other}
        >
            {value === index && <MKBox>{children}</MKBox>}
        </MKBox>
        );
    }
    
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
  

}